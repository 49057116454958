import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { TravelSwitch } from '@entur/travel';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { travelswitch } from './props';
import './traveltag.css';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<TravelSwitch transport=\"bus\">Buss</TravelSwitch>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      TravelSwitch,
      Playground,
      PageHeader,
      ImportStatement,
      travelswitch,
      DefaultLayout,
      _frontmatter
    }} props={travelswitch} mdxType="Playground">
  <TravelSwitch transport="bus" mdxType="TravelSwitch">Buss</TravelSwitch>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "travelswitch"
    }}>{`TravelSwitch`}</h3>
    <ImportStatement imports="TravelSwitch" mdxType="ImportStatement" />
    <Props of={TravelSwitch} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      